/* eslint-disable indent */
/* eslint-disable comma-dangle */
/* eslint-disable no-trailing-spaces */

type StaticImages = {
    readonly [fileName: string]: {
        uri?: string;
    };
}

const imageStaticSourcesByFileName: StaticImages = {
    'inZsmdYNdDE79FvaNQGJ84.png': { uri: '/images/inZsmdYNdDE79FvaNQGJ84.png' },
'2szCikdqsm6CL84sJKro6g.png': { uri: '/images/2szCikdqsm6CL84sJKro6g.png' },
't1L6UXsVrTGhcEf5xgKztN.png': { uri: '/images/t1L6UXsVrTGhcEf5xgKztN.png' },
'vQM7PBVb1onCTBemsk6ftF.png': { uri: '/images/vQM7PBVb1onCTBemsk6ftF.png' },
'orGSqGzkMNxUcn5qM82kWf.png': { uri: '/images/orGSqGzkMNxUcn5qM82kWf.png' },
'fguva2PdbTVrqhpDQez4Wt.png': { uri: '/images/fguva2PdbTVrqhpDQez4Wt.png' },
'jf5EhFTiptRtfJVcx4Evzj.png': { uri: '/images/jf5EhFTiptRtfJVcx4Evzj.png' },
'fs8DpaoVzWRDPpzcVxrb43.png': { uri: '/images/fs8DpaoVzWRDPpzcVxrb43.png' },
'h2JXbroXtVkq7QPePGFbqi.png': { uri: '/images/h2JXbroXtVkq7QPePGFbqi.png' },
'espkttAEbYE2uPU3odsDH7.png': { uri: '/images/espkttAEbYE2uPU3odsDH7.png' },
'ddCewMWyvLPX6xQviHALTX.png': { uri: '/images/ddCewMWyvLPX6xQviHALTX.png' },
'p2K4QcuvJVQiaZfBJGjTUZ.png': { uri: '/images/p2K4QcuvJVQiaZfBJGjTUZ.png' },
'wRcktN5BkBLLiFX48FHtDx.jpg': { uri: '/images/wRcktN5BkBLLiFX48FHtDx.jpg' },
'eMcBEckXErCiDQNrKRo2pz.jpg': { uri: '/images/eMcBEckXErCiDQNrKRo2pz.jpg' },
'egGXjsDhBgk7tBwBt4M4ZS.jpg': { uri: '/images/egGXjsDhBgk7tBwBt4M4ZS.jpg' },
'rUba7KbvHC9ft7C9aJeD1P.jpg': { uri: '/images/rUba7KbvHC9ft7C9aJeD1P.jpg' },
'hMbAWzh7M2BCD1DTpQ8UKL.png': { uri: '/images/hMbAWzh7M2BCD1DTpQ8UKL.png' },
'5sDFgL7PuLXZe8b1SfmHHQ.png': { uri: '/images/5sDFgL7PuLXZe8b1SfmHHQ.png' },
's8EoUeYt7wLSMuV4zgr3rJ.jpg': { uri: '/images/s8EoUeYt7wLSMuV4zgr3rJ.jpg' }
}

export default imageStaticSourcesByFileName
